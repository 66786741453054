<template>
  <div>
    <div class="page-wrapper-xl">
      <div class="nexcard p-4">
        <h3 class="modal-card__title text-center">
          {{ "Final Submission" }}
        </h3>

        <div class="px-5 py-4">
          <div class="card p-3">
            <h5 class="mb-0">
              <b>{{ "Upload Trial Document" }}</b>
            </h5>
            <!-- <small class="text-start">
              (if applicable, else proceed without the document)
            </small> -->

            <div class="row mt-4 mb-3">
              <div class="col">
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  accept=".pdf"
                  @change="handleFileChange"
                />
                <small class="font-12 mx-1">
                  <b-icon icon="info-circle" />
                  Please upload PDF file only
                </small>
              </div>

              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  id="fileName"
                  placeholder="File Name"
                  v-model="selectedFileName"
                />
                <small class="font-12 mx-1">
                  <b-icon icon="info-circle" />
                  Please rename your file
                </small>
              </div>
            </div>

            <div>
              <button
                id="buttonFile"
                class="btn btn-primary float-right"
                @click="handleFileUpload"
              >
                <div class="row">
                  <div class="col-1">
                    <span class="material-icons-outlined">file_upload</span>
                  </div>
                  <div class="col">
                    {{ "Upload File" }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <!-- <div
          v-if="isWitnessClientEmpty && isWitnessOpposingEmpty"
          class="mx-5">
          <p class="text-center text-danger mb-2">
            <b>There is a lack of available information regarding<br>
            the witnesses associated with the client or any opposing witnesses.</b>
          </p>
          <p class="text-center text-danger">
            <b>Therefore, Jury instruction is necessary to get Trial Phase documents.</b>
          </p>
        </div>
        <div v-else-if="!file_in_upload" style="height: 2.5rem;">
          <button
            @click="noFileUpload"
            id="buttonFile"
            class="btn btn-primary float-right d-flex align-items-center"
          >
            <span>Proceed without uploading Jury Instruction</span>
            <span class="material-symbols-outlined text-start font-18 ms-2">
              arrow_forward_ios
            </span>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import LegalExpertSystem from "@/store/LES.js";
import { createFormData, validateInput } from "../../store/utils";
export default {
  data() {
    return {
      file_in_upload: false,
      selectedFileName: "",
    };
  },
  methods: {
    toastError(flag) {
      this.$toast.error({
        "invalid": "Please input a valid file name\nWhich should not include: '/' '(' ')' ','",
        "empty": "Please input a valid file and file name\nWhich should not include: '/' '(' ')' ','",
        "file": "Please upload a .pdf file",
      }[flag]);
    },

    handleFileChange(event) {
      const file = validateInput(event.target.files[0], "file", ["pdf"]);
      if (file[0]) {
        this.selectedFileName = file[1].name;
      } else if (!file[0]) {
        if (file[1] !== "empty") this.toastError(file[1]);
        this.selectedFileName = "";
      }
    },

    handleFileUpload() {
      this.file_in_upload = true;

      const File = validateInput(document.getElementById("formFile").files[0], "file", ["pdf"]);
      const Filename = validateInput(this.selectedFileName, "string", ["filename"]);

      if (!File[0]) {
        this.toastError(File[1]);
        return;
      }
      if (!Filename[0]) {
        this.toastError(Filename[1]);
        return;
      }

      LegalExpertSystem.finalSubmission(
        this.$route.params.id,
        createFormData({
          "trial_doc": File[1],
        })
      )
        .then((res) => {
          this.$toast.success(res.data.message);
          this.$router.push({
            name: "LegalExpertSystemPreTrialResult",
            params: {
              id: this.$route.params.id,
              category: "postTrial", // post trial?
            },
          });
        })
        .catch(err => { console.log(err); });
    },
  },
};
</script>

<style>
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* padding: 1.5rem; */
}
</style>